import moment from 'moment'

import 'moment/locale/ar'

import 'moment-shortformat'
import 'moment-timezone'

moment.locale('ar')

export default (ctx, inject) => {
  ctx.$moment = moment
  inject('moment', moment)
}
