export const BreadCrumbs = () => import('../../components/BreadCrumbs.vue' /* webpackChunkName: "components/bread-crumbs" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Navbar = () => import('../../components/Navbar.vue' /* webpackChunkName: "components/navbar" */).then(c => wrapFunctional(c.default || c))
export const PaymentDepositForm = () => import('../../components/PaymentDepositForm.vue' /* webpackChunkName: "components/payment-deposit-form" */).then(c => wrapFunctional(c.default || c))
export const Receipt = () => import('../../components/Receipt.vue' /* webpackChunkName: "components/receipt" */).then(c => wrapFunctional(c.default || c))
export const Tag = () => import('../../components/Tag.vue' /* webpackChunkName: "components/tag" */).then(c => wrapFunctional(c.default || c))
export const VDropdown = () => import('../../components/v-dropdown.vue' /* webpackChunkName: "components/v-dropdown" */).then(c => wrapFunctional(c.default || c))
export const AppButton = () => import('../../components/AppButton/AppButton.vue' /* webpackChunkName: "components/app-button" */).then(c => wrapFunctional(c.default || c))
export const AppButtonPrimaryButton = () => import('../../components/AppButton/PrimaryButton.vue' /* webpackChunkName: "components/app-button-primary-button" */).then(c => wrapFunctional(c.default || c))
export const AppSectionTitle = () => import('../../components/AppSectionTitle/AppSectionTitle.vue' /* webpackChunkName: "components/app-section-title" */).then(c => wrapFunctional(c.default || c))
export const BookingServices = () => import('../../components/Booking/BookingServices.vue' /* webpackChunkName: "components/booking-services" */).then(c => wrapFunctional(c.default || c))
export const BookingUserInformation = () => import('../../components/Booking/BookingUserInformation.vue' /* webpackChunkName: "components/booking-user-information" */).then(c => wrapFunctional(c.default || c))
export const Breadcrump = () => import('../../components/Breadcrump/breadcrump.vue' /* webpackChunkName: "components/breadcrump" */).then(c => wrapFunctional(c.default || c))
export const BusinessEventBackgroundGraphics = () => import('../../components/BusinessEvent/BackgroundGraphics.vue' /* webpackChunkName: "components/business-event-background-graphics" */).then(c => wrapFunctional(c.default || c))
export const BusinessEventCard = () => import('../../components/BusinessEvent/BusinessEventCard.vue' /* webpackChunkName: "components/business-event-card" */).then(c => wrapFunctional(c.default || c))
export const BusinessEventDetails = () => import('../../components/BusinessEvent/EventDetails.vue' /* webpackChunkName: "components/business-event-details" */).then(c => wrapFunctional(c.default || c))
export const BusinessEventPackage = () => import('../../components/BusinessEvent/EventPackage.vue' /* webpackChunkName: "components/business-event-package" */).then(c => wrapFunctional(c.default || c))
export const BusinessEventMyEventRequests = () => import('../../components/BusinessEvent/MyEventRequests.vue' /* webpackChunkName: "components/business-event-my-event-requests" */).then(c => wrapFunctional(c.default || c))
export const BusinessEventPersonalInformation = () => import('../../components/BusinessEvent/PersonalInformation.vue' /* webpackChunkName: "components/business-event-personal-information" */).then(c => wrapFunctional(c.default || c))
export const BusinessEventIntroStep = () => import('../../components/BusinessEvent/introStep.vue' /* webpackChunkName: "components/business-event-intro-step" */).then(c => wrapFunctional(c.default || c))
export const CommonPagesMyBookings = () => import('../../components/CommonPages/myBookings.vue' /* webpackChunkName: "components/common-pages-my-bookings" */).then(c => wrapFunctional(c.default || c))
export const EventIntro = () => import('../../components/EventIntro/EventIntro.vue' /* webpackChunkName: "components/event-intro" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/Loader/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const LoaderLoader = () => import('../../components/Loader/_Loader.js' /* webpackChunkName: "components/loader-loader" */).then(c => wrapFunctional(c.default || c))
export const LoadersBookingOptionsLoader = () => import('../../components/Loaders/BookingOptionsLoader.vue' /* webpackChunkName: "components/loaders-booking-options-loader" */).then(c => wrapFunctional(c.default || c))
export const LoadersOptionDetailsLoader = () => import('../../components/Loaders/OptionDetailsLoader.vue' /* webpackChunkName: "components/loaders-option-details-loader" */).then(c => wrapFunctional(c.default || c))
export const LoadersPlaceDetailsLoader = () => import('../../components/Loaders/PlaceDetailsLoader.vue' /* webpackChunkName: "components/loaders-place-details-loader" */).then(c => wrapFunctional(c.default || c))
export const ModalsModal = () => import('../../components/Modals/Modal.vue' /* webpackChunkName: "components/modals-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsOneStepLoginActivateCodeModal = () => import('../../components/Modals/OneStepLoginActivateCodeModal.vue' /* webpackChunkName: "components/modals-one-step-login-activate-code-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsSearchBoxModal = () => import('../../components/Modals/SearchBoxModal.vue' /* webpackChunkName: "components/modals-search-box-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsServiceModal = () => import('../../components/Modals/ServiceModal.vue' /* webpackChunkName: "components/modals-service-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsEditServicesModal = () => import('../../components/Modals/editServicesModal.vue' /* webpackChunkName: "components/modals-edit-services-modal" */).then(c => wrapFunctional(c.default || c))
export const PlaceDetailsHeader = () => import('../../components/PlaceDetails/Header.vue' /* webpackChunkName: "components/place-details-header" */).then(c => wrapFunctional(c.default || c))
export const PlaceDetailsOptionCard = () => import('../../components/PlaceDetails/OptionCard.vue' /* webpackChunkName: "components/place-details-option-card" */).then(c => wrapFunctional(c.default || c))
export const PlaceDetailsOptions = () => import('../../components/PlaceDetails/Options.vue' /* webpackChunkName: "components/place-details-options" */).then(c => wrapFunctional(c.default || c))
export const PlaceAppDetails = () => import('../../components/PlaceDetails/PlaceAppDetails.vue' /* webpackChunkName: "components/place-app-details" */).then(c => wrapFunctional(c.default || c))
export const PlaceDetails = () => import('../../components/PlaceDetails/PlaceDetails.vue' /* webpackChunkName: "components/place-details" */).then(c => wrapFunctional(c.default || c))
export const PlaceDetailsSearchBar = () => import('../../components/PlaceDetails/SearchBar.vue' /* webpackChunkName: "components/place-details-search-bar" */).then(c => wrapFunctional(c.default || c))
export const PlannerImageHeader = () => import('../../components/Planner/ImageHeader.vue' /* webpackChunkName: "components/planner-image-header" */).then(c => wrapFunctional(c.default || c))
export const PlannerCategoriesVenuesOption = () => import('../../components/Planner/categoriesVenuesOption.vue' /* webpackChunkName: "components/planner-categories-venues-option" */).then(c => wrapFunctional(c.default || c))
export const PlannerEditCategoryOptionsModal = () => import('../../components/Planner/editCategoryOptionsModal.vue' /* webpackChunkName: "components/planner-edit-category-options-modal" */).then(c => wrapFunctional(c.default || c))
export const PlannerEditPlannerModal = () => import('../../components/Planner/editPlannerModal.vue' /* webpackChunkName: "components/planner-edit-planner-modal" */).then(c => wrapFunctional(c.default || c))
export const PlannerMyPlannerCard = () => import('../../components/Planner/myPlannerCard.vue' /* webpackChunkName: "components/planner-my-planner-card" */).then(c => wrapFunctional(c.default || c))
export const PlannerOptionCard = () => import('../../components/Planner/optionCard.vue' /* webpackChunkName: "components/planner-option-card" */).then(c => wrapFunctional(c.default || c))
export const PlannerSelectCategories = () => import('../../components/Planner/selectCategories.vue' /* webpackChunkName: "components/planner-select-categories" */).then(c => wrapFunctional(c.default || c))
export const PlannerSelectCategoriesCard = () => import('../../components/Planner/selectCategoriesCard.vue' /* webpackChunkName: "components/planner-select-categories-card" */).then(c => wrapFunctional(c.default || c))
export const PlannerSelectOptions = () => import('../../components/Planner/selectOptions.vue' /* webpackChunkName: "components/planner-select-options" */).then(c => wrapFunctional(c.default || c))
export const PlannerShareModal = () => import('../../components/Planner/shareModal.vue' /* webpackChunkName: "components/planner-share-modal" */).then(c => wrapFunctional(c.default || c))
export const PlannerVenuesOptions = () => import('../../components/Planner/venuesOptions.vue' /* webpackChunkName: "components/planner-venues-options" */).then(c => wrapFunctional(c.default || c))
export const SearchFormContainer = () => import('../../components/SearchFormContainer/SearchFormContainer.vue' /* webpackChunkName: "components/search-form-container" */).then(c => wrapFunctional(c.default || c))
export const SearchTextContainer = () => import('../../components/SearchFormContainer/SearchTextContainer.vue' /* webpackChunkName: "components/search-text-container" */).then(c => wrapFunctional(c.default || c))
export const SubdomainFooter = () => import('../../components/Subdomain/Footer.vue' /* webpackChunkName: "components/subdomain-footer" */).then(c => wrapFunctional(c.default || c))
export const SubdomainHeader = () => import('../../components/Subdomain/Header.vue' /* webpackChunkName: "components/subdomain-header" */).then(c => wrapFunctional(c.default || c))
export const SubdomainNavLinks = () => import('../../components/Subdomain/NavLinks.vue' /* webpackChunkName: "components/subdomain-nav-links" */).then(c => wrapFunctional(c.default || c))
export const SubdomainNavLinksOld = () => import('../../components/Subdomain/NavLinksOld.vue' /* webpackChunkName: "components/subdomain-nav-links-old" */).then(c => wrapFunctional(c.default || c))
export const SubdomainPage = () => import('../../components/Subdomain/Page.vue' /* webpackChunkName: "components/subdomain-page" */).then(c => wrapFunctional(c.default || c))
export const SubdomainPlaceDetails = () => import('../../components/Subdomain/PlaceDetails.vue' /* webpackChunkName: "components/subdomain-place-details" */).then(c => wrapFunctional(c.default || c))
export const SubdomainSaaSHeader = () => import('../../components/Subdomain/SaaSHeader.vue' /* webpackChunkName: "components/subdomain-saa-s-header" */).then(c => wrapFunctional(c.default || c))
export const SubdomainOldHeader = () => import('../../components/SubdomainOld/Header.vue' /* webpackChunkName: "components/subdomain-old-header" */).then(c => wrapFunctional(c.default || c))
export const SubdomainOldNavLinks = () => import('../../components/SubdomainOld/NavLinks.vue' /* webpackChunkName: "components/subdomain-old-nav-links" */).then(c => wrapFunctional(c.default || c))
export const SubdomainOldPage = () => import('../../components/SubdomainOld/Page.vue' /* webpackChunkName: "components/subdomain-old-page" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/Tabs/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const VendorRequestBackgroundGraphics = () => import('../../components/VendorRequest/BackgroundGraphics.vue' /* webpackChunkName: "components/vendor-request-background-graphics" */).then(c => wrapFunctional(c.default || c))
export const VendorRequestPersonalInformation = () => import('../../components/VendorRequest/PersonalInformation.vue' /* webpackChunkName: "components/vendor-request-personal-information" */).then(c => wrapFunctional(c.default || c))
export const VendorDetails = () => import('../../components/VendorRequest/VendorDetails.vue' /* webpackChunkName: "components/vendor-details" */).then(c => wrapFunctional(c.default || c))
export const VendorRequestIntroStep = () => import('../../components/VendorRequest/introStep.vue' /* webpackChunkName: "components/vendor-request-intro-step" */).then(c => wrapFunctional(c.default || c))
export const PaymentHyperpayForm = () => import('../../components/payment/hyperpayForm.vue' /* webpackChunkName: "components/payment-hyperpay-form" */).then(c => wrapFunctional(c.default || c))
export const PaymentModal = () => import('../../components/payment/paymentModal.vue' /* webpackChunkName: "components/payment-modal" */).then(c => wrapFunctional(c.default || c))
export const Reviews = () => import('../../components/reviews/index.vue' /* webpackChunkName: "components/reviews" */).then(c => wrapFunctional(c.default || c))
export const ReviewsReview = () => import('../../components/reviews/review.vue' /* webpackChunkName: "components/reviews-review" */).then(c => wrapFunctional(c.default || c))
export const BookingBookingProcessBackgroundGraphics = () => import('../../components/Booking/BookingProcess/BackgroundGraphics.vue' /* webpackChunkName: "components/booking-booking-process-background-graphics" */).then(c => wrapFunctional(c.default || c))
export const BookingProcess = () => import('../../components/Booking/BookingProcess/BookingProcess.vue' /* webpackChunkName: "components/booking-process" */).then(c => wrapFunctional(c.default || c))
export const BookingProcessScript = () => import('../../components/Booking/BookingProcess/bookingProcessScript.js' /* webpackChunkName: "components/booking-process-script" */).then(c => wrapFunctional(c.default || c))
export const BusinessEventPlannerCategoryOptions = () => import('../../components/BusinessEvent/Planner/CategoryOptions.vue' /* webpackChunkName: "components/business-event-planner-category-options" */).then(c => wrapFunctional(c.default || c))
export const BusinessEventPlannerOptionCard = () => import('../../components/BusinessEvent/Planner/OptionCard.vue' /* webpackChunkName: "components/business-event-planner-option-card" */).then(c => wrapFunctional(c.default || c))
export const BusinessEventPlannerOptionDetailsModal = () => import('../../components/BusinessEvent/Planner/OptionDetailsModal.vue' /* webpackChunkName: "components/business-event-planner-option-details-modal" */).then(c => wrapFunctional(c.default || c))
export const BusinessEventPlannerHeader = () => import('../../components/BusinessEvent/Planner/PlannerHeader.vue' /* webpackChunkName: "components/business-event-planner-header" */).then(c => wrapFunctional(c.default || c))
export const BusinessEventPlannerSkeletonLoader = () => import('../../components/BusinessEvent/Planner/SkeletonLoader.vue' /* webpackChunkName: "components/business-event-planner-skeleton-loader" */).then(c => wrapFunctional(c.default || c))
export const BusinessEventPlannerPrivateShareOptionCard = () => import('../../components/BusinessEvent/Planner/privateShareOptionCard.vue' /* webpackChunkName: "components/business-event-planner-private-share-option-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardBooking = () => import('../../components/Dashboard/Booking/Booking.vue' /* webpackChunkName: "components/dashboard-booking" */).then(c => wrapFunctional(c.default || c))
export const DashboardBookingInfoWrapper = () => import('../../components/Dashboard/BookingInfo/BookingInfoWrapper.vue' /* webpackChunkName: "components/dashboard-booking-info-wrapper" */).then(c => wrapFunctional(c.default || c))
export const DashboardPlaceDetails = () => import('../../components/Dashboard/BookingInfo/DashboardPlaceDetails.vue' /* webpackChunkName: "components/dashboard-place-details" */).then(c => wrapFunctional(c.default || c))
export const DashboardDashNavbar = () => import('../../components/Dashboard/DashNavbar/DashNavbar.vue' /* webpackChunkName: "components/dashboard-dash-navbar" */).then(c => wrapFunctional(c.default || c))
export const DashboardSidebar = () => import('../../components/Dashboard/Sidebar/Sidebar.vue' /* webpackChunkName: "components/dashboard-sidebar" */).then(c => wrapFunctional(c.default || c))
export const HomeComponentsAllPlacess = () => import('../../components/HomeComponents/AllPlacess/AllPlacess.vue' /* webpackChunkName: "components/home-components-all-placess" */).then(c => wrapFunctional(c.default || c))
export const HomeComponentsAllPlacessPlacesHorizontal = () => import('../../components/HomeComponents/AllPlacess/PlacesHorizontal.vue' /* webpackChunkName: "components/home-components-all-placess-places-horizontal" */).then(c => wrapFunctional(c.default || c))
export const HomeComponentsBusinessEvents = () => import('../../components/HomeComponents/BusinessEvents/BusinessEvents.vue' /* webpackChunkName: "components/home-components-business-events" */).then(c => wrapFunctional(c.default || c))
export const HomeComponentsHeroSection = () => import('../../components/HomeComponents/HeroSection/HeroSection.vue' /* webpackChunkName: "components/home-components-hero-section" */).then(c => wrapFunctional(c.default || c))
export const HomeComponentsHeroSectionHeroSection = () => import('../../components/HomeComponents/HeroSection/_heroSection.js' /* webpackChunkName: "components/home-components-hero-section-hero-section" */).then(c => wrapFunctional(c.default || c))
export const HomeComponentsHeroSection1HeroSection = () => import('../../components/HomeComponents/HeroSection1/_heroSection.js' /* webpackChunkName: "components/home-components-hero-section1-hero-section" */).then(c => wrapFunctional(c.default || c))
export const HomeComponentsLastNews = () => import('../../components/HomeComponents/LastNews/LastNews.vue' /* webpackChunkName: "components/home-components-last-news" */).then(c => wrapFunctional(c.default || c))
export const HomeComponentsLastNewsLastNews = () => import('../../components/HomeComponents/LastNews/_lastNews.js' /* webpackChunkName: "components/home-components-last-news-last-news" */).then(c => wrapFunctional(c.default || c))
export const HomeComponentsNewsLetter = () => import('../../components/HomeComponents/NewsLetter/NewsLetter.vue' /* webpackChunkName: "components/home-components-news-letter" */).then(c => wrapFunctional(c.default || c))
export const HomeComponentsPlannersPlanner = () => import('../../components/HomeComponents/Planners/Planner.vue' /* webpackChunkName: "components/home-components-planners-planner" */).then(c => wrapFunctional(c.default || c))
export const HomeComponentsServices = () => import('../../components/HomeComponents/Services/Services.vue' /* webpackChunkName: "components/home-components-services" */).then(c => wrapFunctional(c.default || c))
export const ModalsActivateCodeModal = () => import('../../components/Modals/ActivateCodeModal/ActivateCodeModal.vue' /* webpackChunkName: "components/modals-activate-code-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsFieldIcon = () => import('../../components/Modals/FieldIcon/FieldIcon.vue' /* webpackChunkName: "components/modals-field-icon" */).then(c => wrapFunctional(c.default || c))
export const ModalsFieldInput = () => import('../../components/Modals/FieldInput/FieldInput.vue' /* webpackChunkName: "components/modals-field-input" */).then(c => wrapFunctional(c.default || c))
export const ModalsLoginModal = () => import('../../components/Modals/LoginModal/LoginModal.vue' /* webpackChunkName: "components/modals-login-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalAvatar = () => import('../../components/Modals/ModalAvatar/ModalAvatar.vue' /* webpackChunkName: "components/modals-modal-avatar" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalContainer = () => import('../../components/Modals/ModalContainer/ModalContainer.vue' /* webpackChunkName: "components/modals-modal-container" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalLink = () => import('../../components/Modals/ModalLink/ModalLink.vue' /* webpackChunkName: "components/modals-modal-link" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalTitle = () => import('../../components/Modals/ModalTitle/ModalTitle.vue' /* webpackChunkName: "components/modals-modal-title" */).then(c => wrapFunctional(c.default || c))
export const ModalsRegistrationModal = () => import('../../components/Modals/RegistrationModal/RegistrationModal.vue' /* webpackChunkName: "components/modals-registration-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsResetPasswordModal = () => import('../../components/Modals/ResetPasswordModal/ResetPasswordModal.vue' /* webpackChunkName: "components/modals-reset-password-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsRetrievePasswordModal = () => import('../../components/Modals/RetrievePasswordModal/RetrievePasswordModal.vue' /* webpackChunkName: "components/modals-retrieve-password-modal" */).then(c => wrapFunctional(c.default || c))
export const PlaceDetailsBookingOptionDetailsBranchModal = () => import('../../components/PlaceDetails/BookingOptionDetails/BranchModal.vue' /* webpackChunkName: "components/place-details-booking-option-details-branch-modal" */).then(c => wrapFunctional(c.default || c))
export const PlaceDetailsBookingOptionDetailsDateModal = () => import('../../components/PlaceDetails/BookingOptionDetails/DateModal.vue' /* webpackChunkName: "components/place-details-booking-option-details-date-modal" */).then(c => wrapFunctional(c.default || c))
export const PlaceDetailsBookingOptionDetailsEventTypeModal = () => import('../../components/PlaceDetails/BookingOptionDetails/EventTypeModal.vue' /* webpackChunkName: "components/place-details-booking-option-details-event-type-modal" */).then(c => wrapFunctional(c.default || c))
export const PlaceDetailsBookingOptionDetailsGuestsInputModal = () => import('../../components/PlaceDetails/BookingOptionDetails/GuestsInputModal.vue' /* webpackChunkName: "components/place-details-booking-option-details-guests-input-modal" */).then(c => wrapFunctional(c.default || c))
export const PlaceDetailsBookingOptionDetailsOldServiceDetailsModal = () => import('../../components/PlaceDetails/BookingOptionDetails/OldServiceDetailsModal.vue' /* webpackChunkName: "components/place-details-booking-option-details-old-service-details-modal" */).then(c => wrapFunctional(c.default || c))
export const PlaceDetailsBookingOptionPageContent = () => import('../../components/PlaceDetails/BookingOptionDetails/OptionPageContent.vue' /* webpackChunkName: "components/place-details-booking-option-page-content" */).then(c => wrapFunctional(c.default || c))
export const PlaceDetailsBookingOptionDetailsProvinceModal = () => import('../../components/PlaceDetails/BookingOptionDetails/ProvinceModal.vue' /* webpackChunkName: "components/place-details-booking-option-details-province-modal" */).then(c => wrapFunctional(c.default || c))
export const PlaceDetailsBookingOptionDetailsSearchBox = () => import('../../components/PlaceDetails/BookingOptionDetails/SearchBox.vue' /* webpackChunkName: "components/place-details-booking-option-details-search-box" */).then(c => wrapFunctional(c.default || c))
export const PlaceDetailsBookingOptionDetailsSearchBoxModal = () => import('../../components/PlaceDetails/BookingOptionDetails/SearchBoxModal.vue' /* webpackChunkName: "components/place-details-booking-option-details-search-box-modal" */).then(c => wrapFunctional(c.default || c))
export const PlaceDetailsBookingOptionDetailsServiceDetailsModal = () => import('../../components/PlaceDetails/BookingOptionDetails/ServiceDetailsModal.vue' /* webpackChunkName: "components/place-details-booking-option-details-service-details-modal" */).then(c => wrapFunctional(c.default || c))
export const PlaceDetailsBookingOptionDetailsServicesOptions = () => import('../../components/PlaceDetails/BookingOptionDetails/ServicesOptions.vue' /* webpackChunkName: "components/place-details-booking-option-details-services-options" */).then(c => wrapFunctional(c.default || c))
export const PlaceDetailsBookingOptionDetailsTabbyModal = () => import('../../components/PlaceDetails/BookingOptionDetails/tabbyModal.vue' /* webpackChunkName: "components/place-details-booking-option-details-tabby-modal" */).then(c => wrapFunctional(c.default || c))
export const SearchForm = () => import('../../components/SearchFormContainer/SearchForm/SearchForm.vue' /* webpackChunkName: "components/search-form" */).then(c => wrapFunctional(c.default || c))
export const SearchFormText = () => import('../../components/SearchFormContainer/SearchForm/SearchFormText.vue' /* webpackChunkName: "components/search-form-text" */).then(c => wrapFunctional(c.default || c))
export const SubdomainLoadersPlaceDetailsLoader = () => import('../../components/Subdomain/Loaders/PlaceDetailsLoader.vue' /* webpackChunkName: "components/subdomain-loaders-place-details-loader" */).then(c => wrapFunctional(c.default || c))
export const SubdomainOldLoadersPlaceDetailsLoader = () => import('../../components/SubdomainOld/Loaders/PlaceDetailsLoader.vue' /* webpackChunkName: "components/subdomain-old-loaders-place-details-loader" */).then(c => wrapFunctional(c.default || c))
export const DashboardBookingDetails = () => import('../../components/Dashboard/BookingInfo/BookingDetails/BookingDetails.vue' /* webpackChunkName: "components/dashboard-booking-details" */).then(c => wrapFunctional(c.default || c))
export const DashboardSidebarItem = () => import('../../components/Dashboard/Sidebar/SidebarItem/SidebarItem.vue' /* webpackChunkName: "components/dashboard-sidebar-item" */).then(c => wrapFunctional(c.default || c))
export const SearchFormContainerSearchFormDatePickerCustom = () => import('../../components/SearchFormContainer/SearchForm/DatePickerCustom/DatePickerCustom.vue' /* webpackChunkName: "components/search-form-container-search-form-date-picker-custom" */).then(c => wrapFunctional(c.default || c))
export const SearchFormContainerSearchFormFieldContainer = () => import('../../components/SearchFormContainer/SearchForm/FieldContainer/FieldContainer.vue' /* webpackChunkName: "components/search-form-container-search-form-field-container" */).then(c => wrapFunctional(c.default || c))
export const DashboardBookingData = () => import('../../components/Dashboard/BookingInfo/BookingDetails/BookingData/BookingData.vue' /* webpackChunkName: "components/dashboard-booking-data" */).then(c => wrapFunctional(c.default || c))
export const DashboardBookingStatus = () => import('../../components/Dashboard/BookingInfo/BookingDetails/BookingStatus/BookingStatus.vue' /* webpackChunkName: "components/dashboard-booking-status" */).then(c => wrapFunctional(c.default || c))
export const DashboardBookingSummary = () => import('../../components/Dashboard/BookingInfo/BookingDetails/BookingSummary/BookingSummary.vue' /* webpackChunkName: "components/dashboard-booking-summary" */).then(c => wrapFunctional(c.default || c))
export const DashboardBookingInfoBookingDetailsUserData = () => import('../../components/Dashboard/BookingInfo/BookingDetails/UserData/UserData.vue' /* webpackChunkName: "components/dashboard-booking-info-booking-details-user-data" */).then(c => wrapFunctional(c.default || c))
export const DashboardBookingInfoDisplayWrapper = () => import('../../components/Dashboard/BookingInfo/BookingDetails/UtilityComponents/InfoDisplayWrapper.vue' /* webpackChunkName: "components/dashboard-booking-info-display-wrapper" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
