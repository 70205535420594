const middleware = {}

middleware['addHeaders'] = require('../middleware/addHeaders.js')
middleware['addHeaders'] = middleware['addHeaders'].default || middleware['addHeaders']

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['checkAuth'] = require('../middleware/checkAuth.js')
middleware['checkAuth'] = middleware['checkAuth'].default || middleware['checkAuth']

middleware['parseDataToState'] = require('../middleware/parseDataToState.js')
middleware['parseDataToState'] = middleware['parseDataToState'].default || middleware['parseDataToState']

middleware['saasPrefix'] = require('../middleware/saasPrefix.js')
middleware['saasPrefix'] = middleware['saasPrefix'].default || middleware['saasPrefix']

export default middleware
