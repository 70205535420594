import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6370b013 = () => interopDefault(import('../pages/aboutUs.vue' /* webpackChunkName: "pages/aboutUs" */))
const _64b92a38 = () => interopDefault(import('../pages/businessEvents/index.vue' /* webpackChunkName: "pages/businessEvents/index" */))
const _6537b49f = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _72714b4c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _752b24e4 = () => interopDefault(import('../pages/payment.vue' /* webpackChunkName: "pages/payment" */))
const _42938b12 = () => interopDefault(import('../pages/places/index.vue' /* webpackChunkName: "pages/places/index" */))
const _a57459bc = () => interopDefault(import('../pages/plannerAi.vue' /* webpackChunkName: "pages/plannerAi" */))
const _4a9610cc = () => interopDefault(import('../pages/planners/index.vue' /* webpackChunkName: "pages/planners/index" */))
const _843a6b60 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _0dbb98dc = () => interopDefault(import('../pages/sub-domain/index.vue' /* webpackChunkName: "pages/sub-domain/index" */))
const _56a2aa94 = () => interopDefault(import('../pages/sub-domain-old/index.vue' /* webpackChunkName: "pages/sub-domain-old/index" */))
const _aff42952 = () => interopDefault(import('../pages/vendorRequests/index.vue' /* webpackChunkName: "pages/vendorRequests/index" */))
const _d229c972 = () => interopDefault(import('../pages/businessEvents/myEventRequests/index.vue' /* webpackChunkName: "pages/businessEvents/myEventRequests/index" */))
const _8c3f2b5a = () => interopDefault(import('../pages/businessEvents/thankyou.vue' /* webpackChunkName: "pages/businessEvents/thankyou" */))
const _3dc84218 = () => interopDefault(import('../pages/businessEvents/welcome.vue' /* webpackChunkName: "pages/businessEvents/welcome" */))
const _0de8c134 = () => interopDefault(import('../pages/customSaas/qosoor/index.vue' /* webpackChunkName: "pages/customSaas/qosoor/index" */))
const _2ee0ce7d = () => interopDefault(import('../pages/dashboard/bookings.vue' /* webpackChunkName: "pages/dashboard/bookings" */))
const _5bfbdecd = () => interopDefault(import('../pages/dashboard/edit.vue' /* webpackChunkName: "pages/dashboard/edit" */))
const _0c0df3be = () => interopDefault(import('../pages/dashboard/history.vue' /* webpackChunkName: "pages/dashboard/history" */))
const _7af3a2e4 = () => interopDefault(import('../pages/planners/startNewPlanner.vue' /* webpackChunkName: "pages/planners/startNewPlanner" */))
const _0e93e6c2 = () => interopDefault(import('../pages/sub-domain-old/404/index.vue' /* webpackChunkName: "pages/sub-domain-old/404/index" */))
const _9612601e = () => interopDefault(import('../pages/sub-domain-old/about.vue' /* webpackChunkName: "pages/sub-domain-old/about" */))
const _54ff6c04 = () => interopDefault(import('../pages/sub-domain-old/contact.vue' /* webpackChunkName: "pages/sub-domain-old/contact" */))
const _507ee05c = () => interopDefault(import('../pages/sub-domain-old/myBookings.vue' /* webpackChunkName: "pages/sub-domain-old/myBookings" */))
const _3d30bc85 = () => interopDefault(import('../pages/sub-domain-old/options/index.vue' /* webpackChunkName: "pages/sub-domain-old/options/index" */))
const _1281d22c = () => interopDefault(import('../pages/sub-domain-old/payment.vue' /* webpackChunkName: "pages/sub-domain-old/payment" */))
const _30991076 = () => interopDefault(import('../pages/sub-domain/404/index.vue' /* webpackChunkName: "pages/sub-domain/404/index" */))
const _23f883d2 = () => interopDefault(import('../pages/sub-domain/about.vue' /* webpackChunkName: "pages/sub-domain/about" */))
const _7e8968aa = () => interopDefault(import('../pages/sub-domain/contact.vue' /* webpackChunkName: "pages/sub-domain/contact" */))
const _eacd8ebe = () => interopDefault(import('../pages/sub-domain/dashboard/index.vue' /* webpackChunkName: "pages/sub-domain/dashboard/index" */))
const _40445208 = () => interopDefault(import('../pages/sub-domain/home/index.vue' /* webpackChunkName: "pages/sub-domain/home/index" */))
const _6f1eed28 = () => interopDefault(import('../pages/sub-domain/myBookings.vue' /* webpackChunkName: "pages/sub-domain/myBookings" */))
const _4b9824ab = () => interopDefault(import('../pages/sub-domain/options/index.vue' /* webpackChunkName: "pages/sub-domain/options/index" */))
const _20491390 = () => interopDefault(import('../pages/sub-domain/payment.vue' /* webpackChunkName: "pages/sub-domain/payment" */))
const _66a23100 = () => interopDefault(import('../pages/vendorRequests/thankyou.vue' /* webpackChunkName: "pages/vendorRequests/thankyou" */))
const _4d5876be = () => interopDefault(import('../pages/customSaas/qosoor/about.vue' /* webpackChunkName: "pages/customSaas/qosoor/about" */))
const _3e35a56c = () => interopDefault(import('../pages/dashboard/booking/bookingCancel.vue' /* webpackChunkName: "pages/dashboard/booking/bookingCancel" */))
const _25d83b0f = () => interopDefault(import('../pages/dashboard/booking/bookingDetailes.vue' /* webpackChunkName: "pages/dashboard/booking/bookingDetailes" */))
const _7277b6c2 = () => interopDefault(import('../pages/dashboard/booking/bookingStateContact.vue' /* webpackChunkName: "pages/dashboard/booking/bookingStateContact" */))
const _a5bb3f10 = () => interopDefault(import('../pages/dashboard/booking/bookingStateContent.vue' /* webpackChunkName: "pages/dashboard/booking/bookingStateContent" */))
const _6883cf85 = () => interopDefault(import('../pages/dashboard/booking/bookingStatePayment.vue' /* webpackChunkName: "pages/dashboard/booking/bookingStatePayment" */))
const _215ce6d0 = () => interopDefault(import('../pages/dashboard/booking/bookingStateStepper.vue' /* webpackChunkName: "pages/dashboard/booking/bookingStateStepper" */))
const _3de1b33b = () => interopDefault(import('../pages/sub-domain/dashboard/bookings.vue' /* webpackChunkName: "pages/sub-domain/dashboard/bookings" */))
const _7e38d28b = () => interopDefault(import('../pages/sub-domain/dashboard/edit.vue' /* webpackChunkName: "pages/sub-domain/dashboard/edit" */))
const _4d0991a3 = () => interopDefault(import('../pages/sub-domain/dashboard/history.vue' /* webpackChunkName: "pages/sub-domain/dashboard/history" */))
const _5c126d4c = () => interopDefault(import('../pages/sub-domain/dashboard/booking/bookingCancel.vue' /* webpackChunkName: "pages/sub-domain/dashboard/booking/bookingCancel" */))
const _ea94fade = () => interopDefault(import('../pages/sub-domain/dashboard/booking/bookingDetailes.vue' /* webpackChunkName: "pages/sub-domain/dashboard/booking/bookingDetailes" */))
const _64cddb21 = () => interopDefault(import('../pages/sub-domain/dashboard/booking/bookingStateContact.vue' /* webpackChunkName: "pages/sub-domain/dashboard/booking/bookingStateContact" */))
const _4b2c16fa = () => interopDefault(import('../pages/sub-domain/dashboard/booking/bookingStateContent.vue' /* webpackChunkName: "pages/sub-domain/dashboard/booking/bookingStateContent" */))
const _068d8607 = () => interopDefault(import('../pages/sub-domain/dashboard/booking/bookingStatePayment.vue' /* webpackChunkName: "pages/sub-domain/dashboard/booking/bookingStatePayment" */))
const _8132c55c = () => interopDefault(import('../pages/sub-domain/dashboard/booking/bookingStateStepper.vue' /* webpackChunkName: "pages/sub-domain/dashboard/booking/bookingStateStepper" */))
const _ac613bba = () => interopDefault(import('../pages/sub-domain/dashboard/booking/_slug.vue' /* webpackChunkName: "pages/sub-domain/dashboard/booking/_slug" */))
const _1e6310ce = () => interopDefault(import('../pages/businessEvents/myEventRequests/_businessEvent/index.vue' /* webpackChunkName: "pages/businessEvents/myEventRequests/_businessEvent/index" */))
const _03a90021 = () => interopDefault(import('../pages/dashboard/booking/_slug.vue' /* webpackChunkName: "pages/dashboard/booking/_slug" */))
const _294b8056 = () => interopDefault(import('../pages/places/details/_id/index.vue' /* webpackChunkName: "pages/places/details/_id/index" */))
const _d5abe24a = () => interopDefault(import('../pages/places/preview/_id/index.vue' /* webpackChunkName: "pages/places/preview/_id/index" */))
const _3591cd87 = () => interopDefault(import('../pages/sub-domain-old/options/_option.vue' /* webpackChunkName: "pages/sub-domain-old/options/_option" */))
const _47bfc42d = () => interopDefault(import('../pages/sub-domain/options/_option.vue' /* webpackChunkName: "pages/sub-domain/options/_option" */))
const _2f5b2b26 = () => interopDefault(import('../pages/businessEvents/myEventRequests/_businessEvent/planners/index.vue' /* webpackChunkName: "pages/businessEvents/myEventRequests/_businessEvent/planners/index" */))
const _0fe6facd = () => interopDefault(import('../pages/places/preview/_id/myBookings.vue' /* webpackChunkName: "pages/places/preview/_id/myBookings" */))
const _7c32f250 = () => interopDefault(import('../pages/businessEvents/myEventRequests/_businessEvent/planners/_id/index.vue' /* webpackChunkName: "pages/businessEvents/myEventRequests/_businessEvent/planners/_id/index" */))
const _5638c7e6 = () => interopDefault(import('../pages/places/details/_id/options/_option.vue' /* webpackChunkName: "pages/places/details/_id/options/_option" */))
const _f8704d12 = () => interopDefault(import('../pages/eventIntros/_id/index.vue' /* webpackChunkName: "pages/eventIntros/_id/index" */))
const _7321e5b7 = () => interopDefault(import('../pages/planners/_id/index.vue' /* webpackChunkName: "pages/planners/_id/index" */))
const _a41ed294 = () => interopDefault(import('../pages/eventIntros/_id/requestEvent.vue' /* webpackChunkName: "pages/eventIntros/_id/requestEvent" */))
const _44f7be44 = () => interopDefault(import('../pages/planners/_id/share.vue' /* webpackChunkName: "pages/planners/_id/share" */))
const _4178d047 = () => interopDefault(import('../pages/sub-domain-old/_bookings/booking.vue' /* webpackChunkName: "pages/sub-domain-old/_bookings/booking" */))
const _fff89526 = () => interopDefault(import('../pages/sub-domain/_bookings/booking.vue' /* webpackChunkName: "pages/sub-domain/_bookings/booking" */))
const _5366677b = () => interopDefault(import('../pages/places/_bookings/_booking.vue' /* webpackChunkName: "pages/places/_bookings/_booking" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutUs",
    component: _6370b013,
    name: "aboutUs___ar"
  }, {
    path: "/businessEvents",
    component: _64b92a38,
    name: "businessEvents___ar"
  }, {
    path: "/dashboard",
    component: _6537b49f,
    name: "dashboard___ar"
  }, {
    path: "/en",
    component: _72714b4c,
    name: "index___en"
  }, {
    path: "/payment",
    component: _752b24e4,
    name: "payment___ar"
  }, {
    path: "/places",
    component: _42938b12,
    name: "places___ar"
  }, {
    path: "/plannerAi",
    component: _a57459bc,
    name: "plannerAi___ar"
  }, {
    path: "/planners",
    component: _4a9610cc,
    name: "planners___ar"
  }, {
    path: "/search",
    component: _843a6b60,
    name: "search___ar"
  }, {
    path: "/sub-domain",
    component: _0dbb98dc,
    name: "sub-domain___ar"
  }, {
    path: "/sub-domain-old",
    component: _56a2aa94,
    name: "sub-domain-old___ar"
  }, {
    path: "/vendorRequests",
    component: _aff42952,
    name: "vendorRequests___ar"
  }, {
    path: "/businessEvents/myEventRequests",
    component: _d229c972,
    name: "businessEvents-myEventRequests___ar"
  }, {
    path: "/businessEvents/thankyou",
    component: _8c3f2b5a,
    name: "businessEvents-thankyou___ar"
  }, {
    path: "/businessEvents/welcome",
    component: _3dc84218,
    name: "businessEvents-welcome___ar"
  }, {
    path: "/customSaas/qosoor",
    component: _0de8c134,
    name: "customSaas-qosoor___ar"
  }, {
    path: "/dashboard/bookings",
    component: _2ee0ce7d,
    name: "dashboard-bookings___ar"
  }, {
    path: "/dashboard/edit",
    component: _5bfbdecd,
    name: "dashboard-edit___ar"
  }, {
    path: "/dashboard/history",
    component: _0c0df3be,
    name: "dashboard-history___ar"
  }, {
    path: "/en/aboutUs",
    component: _6370b013,
    name: "aboutUs___en"
  }, {
    path: "/en/businessEvents",
    component: _64b92a38,
    name: "businessEvents___en"
  }, {
    path: "/en/dashboard",
    component: _6537b49f,
    name: "dashboard___en"
  }, {
    path: "/en/payment",
    component: _752b24e4,
    name: "payment___en"
  }, {
    path: "/en/places",
    component: _42938b12,
    name: "places___en"
  }, {
    path: "/en/plannerAi",
    component: _a57459bc,
    name: "plannerAi___en"
  }, {
    path: "/en/planners",
    component: _4a9610cc,
    name: "planners___en"
  }, {
    path: "/en/search",
    component: _843a6b60,
    name: "search___en"
  }, {
    path: "/en/sub-domain",
    component: _0dbb98dc,
    name: "sub-domain___en"
  }, {
    path: "/en/sub-domain-old",
    component: _56a2aa94,
    name: "sub-domain-old___en"
  }, {
    path: "/en/vendorRequests",
    component: _aff42952,
    name: "vendorRequests___en"
  }, {
    path: "/planners/startNewPlanner",
    component: _7af3a2e4,
    name: "planners-startNewPlanner___ar"
  }, {
    path: "/sub-domain-old/404",
    component: _0e93e6c2,
    name: "sub-domain-old-404___ar"
  }, {
    path: "/sub-domain-old/about",
    component: _9612601e,
    name: "sub-domain-old-about___ar"
  }, {
    path: "/sub-domain-old/contact",
    component: _54ff6c04,
    name: "sub-domain-old-contact___ar"
  }, {
    path: "/sub-domain-old/myBookings",
    component: _507ee05c,
    name: "sub-domain-old-myBookings___ar"
  }, {
    path: "/sub-domain-old/options",
    component: _3d30bc85,
    name: "sub-domain-old-options___ar"
  }, {
    path: "/sub-domain-old/payment",
    component: _1281d22c,
    name: "sub-domain-old-payment___ar"
  }, {
    path: "/sub-domain/404",
    component: _30991076,
    name: "sub-domain-404___ar"
  }, {
    path: "/sub-domain/about",
    component: _23f883d2,
    name: "sub-domain-about___ar"
  }, {
    path: "/sub-domain/contact",
    component: _7e8968aa,
    name: "sub-domain-contact___ar"
  }, {
    path: "/sub-domain/dashboard",
    component: _eacd8ebe,
    name: "sub-domain-dashboard___ar"
  }, {
    path: "/sub-domain/home",
    component: _40445208,
    name: "sub-domain-home___ar"
  }, {
    path: "/sub-domain/myBookings",
    component: _6f1eed28,
    name: "sub-domain-myBookings___ar"
  }, {
    path: "/sub-domain/options",
    component: _4b9824ab,
    name: "sub-domain-options___ar"
  }, {
    path: "/sub-domain/payment",
    component: _20491390,
    name: "sub-domain-payment___ar"
  }, {
    path: "/vendorRequests/thankyou",
    component: _66a23100,
    name: "vendorRequests-thankyou___ar"
  }, {
    path: "/customSaas/qosoor/about",
    component: _4d5876be,
    name: "customSaas-qosoor-about___ar"
  }, {
    path: "/dashboard/booking/bookingCancel",
    component: _3e35a56c,
    name: "dashboard-booking-bookingCancel___ar"
  }, {
    path: "/dashboard/booking/bookingDetailes",
    component: _25d83b0f,
    name: "dashboard-booking-bookingDetailes___ar"
  }, {
    path: "/dashboard/booking/bookingStateContact",
    component: _7277b6c2,
    name: "dashboard-booking-bookingStateContact___ar"
  }, {
    path: "/dashboard/booking/bookingStateContent",
    component: _a5bb3f10,
    name: "dashboard-booking-bookingStateContent___ar"
  }, {
    path: "/dashboard/booking/bookingStatePayment",
    component: _6883cf85,
    name: "dashboard-booking-bookingStatePayment___ar"
  }, {
    path: "/dashboard/booking/bookingStateStepper",
    component: _215ce6d0,
    name: "dashboard-booking-bookingStateStepper___ar"
  }, {
    path: "/en/businessEvents/myEventRequests",
    component: _d229c972,
    name: "businessEvents-myEventRequests___en"
  }, {
    path: "/en/businessEvents/thankyou",
    component: _8c3f2b5a,
    name: "businessEvents-thankyou___en"
  }, {
    path: "/en/businessEvents/welcome",
    component: _3dc84218,
    name: "businessEvents-welcome___en"
  }, {
    path: "/en/customSaas/qosoor",
    component: _0de8c134,
    name: "customSaas-qosoor___en"
  }, {
    path: "/en/dashboard/bookings",
    component: _2ee0ce7d,
    name: "dashboard-bookings___en"
  }, {
    path: "/en/dashboard/edit",
    component: _5bfbdecd,
    name: "dashboard-edit___en"
  }, {
    path: "/en/dashboard/history",
    component: _0c0df3be,
    name: "dashboard-history___en"
  }, {
    path: "/en/planners/startNewPlanner",
    component: _7af3a2e4,
    name: "planners-startNewPlanner___en"
  }, {
    path: "/en/sub-domain-old/404",
    component: _0e93e6c2,
    name: "sub-domain-old-404___en"
  }, {
    path: "/en/sub-domain-old/about",
    component: _9612601e,
    name: "sub-domain-old-about___en"
  }, {
    path: "/en/sub-domain-old/contact",
    component: _54ff6c04,
    name: "sub-domain-old-contact___en"
  }, {
    path: "/en/sub-domain-old/myBookings",
    component: _507ee05c,
    name: "sub-domain-old-myBookings___en"
  }, {
    path: "/en/sub-domain-old/options",
    component: _3d30bc85,
    name: "sub-domain-old-options___en"
  }, {
    path: "/en/sub-domain-old/payment",
    component: _1281d22c,
    name: "sub-domain-old-payment___en"
  }, {
    path: "/en/sub-domain/404",
    component: _30991076,
    name: "sub-domain-404___en"
  }, {
    path: "/en/sub-domain/about",
    component: _23f883d2,
    name: "sub-domain-about___en"
  }, {
    path: "/en/sub-domain/contact",
    component: _7e8968aa,
    name: "sub-domain-contact___en"
  }, {
    path: "/en/sub-domain/dashboard",
    component: _eacd8ebe,
    name: "sub-domain-dashboard___en"
  }, {
    path: "/en/sub-domain/home",
    component: _40445208,
    name: "sub-domain-home___en"
  }, {
    path: "/en/sub-domain/myBookings",
    component: _6f1eed28,
    name: "sub-domain-myBookings___en"
  }, {
    path: "/en/sub-domain/options",
    component: _4b9824ab,
    name: "sub-domain-options___en"
  }, {
    path: "/en/sub-domain/payment",
    component: _20491390,
    name: "sub-domain-payment___en"
  }, {
    path: "/en/vendorRequests/thankyou",
    component: _66a23100,
    name: "vendorRequests-thankyou___en"
  }, {
    path: "/sub-domain/dashboard/bookings",
    component: _3de1b33b,
    name: "sub-domain-dashboard-bookings___ar"
  }, {
    path: "/sub-domain/dashboard/edit",
    component: _7e38d28b,
    name: "sub-domain-dashboard-edit___ar"
  }, {
    path: "/sub-domain/dashboard/history",
    component: _4d0991a3,
    name: "sub-domain-dashboard-history___ar"
  }, {
    path: "/en/customSaas/qosoor/about",
    component: _4d5876be,
    name: "customSaas-qosoor-about___en"
  }, {
    path: "/en/dashboard/booking/bookingCancel",
    component: _3e35a56c,
    name: "dashboard-booking-bookingCancel___en"
  }, {
    path: "/en/dashboard/booking/bookingDetailes",
    component: _25d83b0f,
    name: "dashboard-booking-bookingDetailes___en"
  }, {
    path: "/en/dashboard/booking/bookingStateContact",
    component: _7277b6c2,
    name: "dashboard-booking-bookingStateContact___en"
  }, {
    path: "/en/dashboard/booking/bookingStateContent",
    component: _a5bb3f10,
    name: "dashboard-booking-bookingStateContent___en"
  }, {
    path: "/en/dashboard/booking/bookingStatePayment",
    component: _6883cf85,
    name: "dashboard-booking-bookingStatePayment___en"
  }, {
    path: "/en/dashboard/booking/bookingStateStepper",
    component: _215ce6d0,
    name: "dashboard-booking-bookingStateStepper___en"
  }, {
    path: "/en/sub-domain/dashboard/bookings",
    component: _3de1b33b,
    name: "sub-domain-dashboard-bookings___en"
  }, {
    path: "/en/sub-domain/dashboard/edit",
    component: _7e38d28b,
    name: "sub-domain-dashboard-edit___en"
  }, {
    path: "/en/sub-domain/dashboard/history",
    component: _4d0991a3,
    name: "sub-domain-dashboard-history___en"
  }, {
    path: "/sub-domain/dashboard/booking/bookingCancel",
    component: _5c126d4c,
    name: "sub-domain-dashboard-booking-bookingCancel___ar"
  }, {
    path: "/sub-domain/dashboard/booking/bookingDetailes",
    component: _ea94fade,
    name: "sub-domain-dashboard-booking-bookingDetailes___ar"
  }, {
    path: "/sub-domain/dashboard/booking/bookingStateContact",
    component: _64cddb21,
    name: "sub-domain-dashboard-booking-bookingStateContact___ar"
  }, {
    path: "/sub-domain/dashboard/booking/bookingStateContent",
    component: _4b2c16fa,
    name: "sub-domain-dashboard-booking-bookingStateContent___ar"
  }, {
    path: "/sub-domain/dashboard/booking/bookingStatePayment",
    component: _068d8607,
    name: "sub-domain-dashboard-booking-bookingStatePayment___ar"
  }, {
    path: "/sub-domain/dashboard/booking/bookingStateStepper",
    component: _8132c55c,
    name: "sub-domain-dashboard-booking-bookingStateStepper___ar"
  }, {
    path: "/en/sub-domain/dashboard/booking/bookingCancel",
    component: _5c126d4c,
    name: "sub-domain-dashboard-booking-bookingCancel___en"
  }, {
    path: "/en/sub-domain/dashboard/booking/bookingDetailes",
    component: _ea94fade,
    name: "sub-domain-dashboard-booking-bookingDetailes___en"
  }, {
    path: "/en/sub-domain/dashboard/booking/bookingStateContact",
    component: _64cddb21,
    name: "sub-domain-dashboard-booking-bookingStateContact___en"
  }, {
    path: "/en/sub-domain/dashboard/booking/bookingStateContent",
    component: _4b2c16fa,
    name: "sub-domain-dashboard-booking-bookingStateContent___en"
  }, {
    path: "/en/sub-domain/dashboard/booking/bookingStatePayment",
    component: _068d8607,
    name: "sub-domain-dashboard-booking-bookingStatePayment___en"
  }, {
    path: "/en/sub-domain/dashboard/booking/bookingStateStepper",
    component: _8132c55c,
    name: "sub-domain-dashboard-booking-bookingStateStepper___en"
  }, {
    path: "/en/sub-domain/dashboard/booking/:slug",
    component: _ac613bba,
    name: "sub-domain-dashboard-booking-slug___en"
  }, {
    path: "/en/businessEvents/myEventRequests/:businessEvent",
    component: _1e6310ce,
    name: "businessEvents-myEventRequests-businessEvent___en"
  }, {
    path: "/en/dashboard/booking/:slug?",
    component: _03a90021,
    name: "dashboard-booking-slug___en"
  }, {
    path: "/en/places/details/:id",
    component: _294b8056,
    name: "places-details-id___en"
  }, {
    path: "/en/places/preview/:id",
    component: _d5abe24a,
    name: "places-preview-id___en"
  }, {
    path: "/en/sub-domain-old/options/:option",
    component: _3591cd87,
    name: "sub-domain-old-options-option___en"
  }, {
    path: "/en/sub-domain/options/:option",
    component: _47bfc42d,
    name: "sub-domain-options-option___en"
  }, {
    path: "/sub-domain/dashboard/booking/:slug",
    component: _ac613bba,
    name: "sub-domain-dashboard-booking-slug___ar"
  }, {
    path: "/en/businessEvents/myEventRequests/:businessEvent/planners",
    component: _2f5b2b26,
    name: "businessEvents-myEventRequests-businessEvent-planners___en"
  }, {
    path: "/en/places/preview/:id?/myBookings",
    component: _0fe6facd,
    name: "places-preview-id-myBookings___en"
  }, {
    path: "/en/businessEvents/myEventRequests/:businessEvent/planners/:id",
    component: _7c32f250,
    name: "businessEvents-myEventRequests-businessEvent-planners-id___en"
  }, {
    path: "/en/places/details/:id?/options/:option?",
    component: _5638c7e6,
    name: "places-details-id-options-option___en"
  }, {
    path: "/businessEvents/myEventRequests/:businessEvent",
    component: _1e6310ce,
    name: "businessEvents-myEventRequests-businessEvent___ar"
  }, {
    path: "/dashboard/booking/:slug?",
    component: _03a90021,
    name: "dashboard-booking-slug___ar"
  }, {
    path: "/en/eventIntros/:id",
    component: _f8704d12,
    name: "eventIntros-id___en"
  }, {
    path: "/en/planners/:id",
    component: _7321e5b7,
    name: "planners-id___en"
  }, {
    path: "/places/details/:id",
    component: _294b8056,
    name: "places-details-id___ar"
  }, {
    path: "/places/preview/:id",
    component: _d5abe24a,
    name: "places-preview-id___ar"
  }, {
    path: "/sub-domain-old/options/:option",
    component: _3591cd87,
    name: "sub-domain-old-options-option___ar"
  }, {
    path: "/sub-domain/options/:option",
    component: _47bfc42d,
    name: "sub-domain-options-option___ar"
  }, {
    path: "/businessEvents/myEventRequests/:businessEvent/planners",
    component: _2f5b2b26,
    name: "businessEvents-myEventRequests-businessEvent-planners___ar"
  }, {
    path: "/en/eventIntros/:id?/requestEvent",
    component: _a41ed294,
    name: "eventIntros-id-requestEvent___en"
  }, {
    path: "/en/planners/:id/share",
    component: _44f7be44,
    name: "planners-id-share___en"
  }, {
    path: "/en/sub-domain-old/:bookings?/booking",
    component: _4178d047,
    name: "sub-domain-old-bookings-booking___en"
  }, {
    path: "/en/sub-domain/:bookings?/booking",
    component: _fff89526,
    name: "sub-domain-bookings-booking___en"
  }, {
    path: "/places/preview/:id?/myBookings",
    component: _0fe6facd,
    name: "places-preview-id-myBookings___ar"
  }, {
    path: "/businessEvents/myEventRequests/:businessEvent/planners/:id",
    component: _7c32f250,
    name: "businessEvents-myEventRequests-businessEvent-planners-id___ar"
  }, {
    path: "/places/details/:id?/options/:option?",
    component: _5638c7e6,
    name: "places-details-id-options-option___ar"
  }, {
    path: "/en/places/:bookings/:booking?",
    component: _5366677b,
    name: "places-bookings-booking___en"
  }, {
    path: "/eventIntros/:id",
    component: _f8704d12,
    name: "eventIntros-id___ar"
  }, {
    path: "/planners/:id",
    component: _7321e5b7,
    name: "planners-id___ar"
  }, {
    path: "/eventIntros/:id?/requestEvent",
    component: _a41ed294,
    name: "eventIntros-id-requestEvent___ar"
  }, {
    path: "/planners/:id/share",
    component: _44f7be44,
    name: "planners-id-share___ar"
  }, {
    path: "/sub-domain-old/:bookings?/booking",
    component: _4178d047,
    name: "sub-domain-old-bookings-booking___ar"
  }, {
    path: "/sub-domain/:bookings?/booking",
    component: _fff89526,
    name: "sub-domain-bookings-booking___ar"
  }, {
    path: "/places/:bookings/:booking?",
    component: _5366677b,
    name: "places-bookings-booking___ar"
  }, {
    path: "/",
    component: _72714b4c,
    name: "index___ar"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
